/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.en,
.en * {
  font-family: sans-serif !important;
  direction: ltr !important;
  text-align: left;
  display: inline-block;
}
.ltr {
  direction: ltr;
  display: block;
}
.code-text-field .MuiInputBase-input {
  text-align: center;
  font-weight: 500;
  font-family: sans-serif !important;
  direction: ltr !important;
}
.pointer {
  cursor: pointer;
}
.bg-white {
  background-color: #fff;
}
@media print {
  .no-print {
    display: none !important;
  }
  body {
    min-width: 1200px;
    padding: 16px;
    direction: rtl !important;
  }
  .col-print-3 {
    max-width: 25% !important;
  }
  [class*="MuiPaper-elevation"] {
    box-shadow: none !important;
  }
}
