/* 100 - Thin.
200 - Extra Light, Ultra Light.
300 - Light.
400 - Normal, Book, Regular.
500 - Medium.
600 - Semi Bold, Demi Bold.
700 - Bold.
800 - Extra Bold, Ultra Bold. */

@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 900;
  src: url("./eot/PeydaWeb-Black.eot");
  src: url("./eot/PeydaWeb-Black.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/PeydaWeb-Black.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/PeydaWeb-Black.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 800;
  src: url("./eot/PeydaWeb-ExtraBold.eot");
  src: url("./eot/PeydaWeb-ExtraBold.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/PeydaWeb-ExtraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/PeydaWeb-ExtraBold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 700;
  src: url("./eot/PeydaWeb-Bold.eot");
  src: url("./eot/PeydaWeb-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/PeydaWeb-Bold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/PeydaWeb-Bold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 600;
  src: url("./eot/PeydaWeb-SemiBold.eot");
  src: url("./eot/PeydaWeb-SemiBold.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/PeydaWeb-SemiBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/PeydaWeb-SemiBold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 500;
  src: url("./eot/PeydaWeb-Medium.eot");
  src: url("./eot/PeydaWeb-Medium.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/PeydaWeb-Medium.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/PeydaWeb-Medium.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 400;
  src: url("./eot/PeydaWeb-Regular.eot");
  src: url("./eot/PeydaWeb-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/PeydaWeb-Regular.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/PeydaWeb-Regular.woff")
      format("woff");
  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 300;
  src: url("./eot/peydaWeb-light.eot");
  src: url("./eot/peydaWeb-light.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/peydaWeb-light.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/peydaWeb-light.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 200;
  src: url("./eot/peydaWeb-extralight.eot");
  src: url("./eot/peydaWeb-extralight.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/peydaWeb-extralight.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/peydaWeb-extralight.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Peyda;
  font-style: normal;
  font-weight: 100;
  src: url("./eot/PeydaWeb-Thin.eot");
  src: url("./eot/PeydaWeb-Thin.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */ url("./woff2/PeydaWeb-Thin.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("./woff/PeydaWeb-Thin.woff")
      format("woff");
  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
